import { ApiTransport } from "./config/apiTransport";
import {
  CreditBalanceDTO,
  CreditBalanceResponse,
  EventListDTO,
  EventListResponse,
  OrganizationListDTO,
  OrganizationListResponse,
  UserDto,
  UserResponse,
} from "@root/utils/dtos";

export class UserService {
  private readonly _apiTransport: ApiTransport;

  constructor(sessionCookie?: string) {
    this._apiTransport = new ApiTransport(sessionCookie);
  }

  getUserByEmail = (email: string): UserResponse =>
    this._apiTransport.get<UserDto>(`/api/v1/users/${email}`);

  getOrganizations = (id: string): OrganizationListResponse =>
    this._apiTransport.get<OrganizationListDTO>(
      `/api/v1/users/${id}/organizations`
    );

  getEvents = (id: string, organizationId?: string): EventListResponse => {
    let qs = ``;
    if (organizationId) qs += `organizationId=${organizationId}`;
    if (qs) qs = "?" + qs;

    return this._apiTransport.get<EventListDTO>(
      `/api/v1/users/${id}/events${qs}`
    );
  };

  getCreditBalance = (id: string): CreditBalanceResponse =>
    this._apiTransport.get<CreditBalanceDTO>(
      `/api/v1/users/${id}/credit-balance`
    );

  attendEvent = (userId: string, eventId: string) =>
    this._apiTransport.post(`/api/v1/users/${userId}/events/${eventId}/attend`);

  followEvent = (userId: string, eventId: string) =>
    this._apiTransport.post(`/api/v1/users/${userId}/events/${eventId}/follow`);

  reportEvent = (userId: string, eventId: string, reason: string) =>
    this._apiTransport.post(
      `/api/v1/users/${userId}/events/${eventId}/report`,
      { reason }
    );
}

export const userService = new UserService();
